<template>
    <div>
      <h1>Contact Us</h1>
      <p>Get in touch with us for your web design needs!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactPage'
  }
  </script>
  
  <style scoped>
  /* Optional: Add component-specific styles here */
  </style>
  