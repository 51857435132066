<template>
    <div class="font-sans bg-gray-100 text-gray-800">
        <!-- Hero Section -->
        <section class="relative text-white py-32 px-6 md:px-12">
            <!-- Background Video -->
            <video autoplay muted loop class="absolute inset-0 w-full h-full object-cover">
                <source src="../assets/videos/hero.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
            <!-- Overlay -->
            <div class="absolute inset-0 bg-black opacity-50"></div>
            <div class="relative container mx-auto text-center z-10 flex flex-col items-center justify-center h-full">
                <h1 class="text-4xl md:text-5xl fira-sans-extrabold mb-4 animate__animated animate__fadeIn">Welcome to
                    PeachPixel Design</h1>
                <p
                    class="fira-sans-extralight-italic md:text-xl mb-6 animate__animated animate__fadeIn animate__delay-1s">
                    Bringing your vision to life with modern, professional web design.
                </p>
                <a href="#contact"
                    class="bg-white text-blue-600 px-6 py-3 rounded-lg text-lg fira-sans-semibold hover:bg-gray-200 transition duration-300 ease-in-out">
                    Get Started
                </a>
            </div>
        </section>


        <!-- Services Section -->
        <section id="services" class="py-16 px-6 md:px-12">
            <div class="container mx-auto text-center">
                <h2 class="text-3xl md:text-4xl fira-sans-bold mb-8">Our Services</h2>
                <div class="grid gap-8 md:grid-cols-3">
                    <div class="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                        <img src="../assets/images/concept-of-website-recovery.png" alt="Web Design"
                            class="h-32 mx-auto mb-4">
                        <h3 class="text-2xl fira-sans-semibold mb-2">Web Design</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Custom website development tailored to your brand and business needs.
                        </p>
                    </div>
                    <div class="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                        <img src="../assets/images/concept-of-data-analysis-and-maintenance.png" alt="SEO Optimization"
                            class="h-32 mx-auto mb-4">
                        <h3 class="text-2xl fira-sans-semibold mb-2">SEO Optimization</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Improve your website's visibility with our SEO optimization services.
                        </p>
                    </div>
                    <div class="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                        <img src="../assets/images/ecommerce.svg" alt="E-Commerce" class="h-32 mx-auto mb-4">
                        <h3 class="text-2xl fira-sans-semibold mb-2">E-Commerce</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Set up your online store and start selling your products effectively.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section id="why-choose-us" class="py-20 px-6 md:px-12 bg-white">
            <div class="container mx-auto text-center">
                <h2 class="text-4xl fira-sans-bold mb-8">Why Choose PeachPixel Design?</h2>
                <p class="text-med fira-sans-regular text-gray-700 mb-12 max-w-3xl mx-auto">
                    We're committed to bringing your website vision to life with beautiful designs, competitive pricing,
                    and local expertise.
                </p>

                <!-- Grid Layout for Feature Cards -->
                <div class="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    <!-- Competitively Priced -->
                    <div
                        class="flex flex-col items-center text-center space-y-4 bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                        <img src="../assets/images/pricing.png" alt="Competitive Pricing" class="w-12 h-12">
                        <h3 class="text-2xl fira-sans-semibold">Competitively Priced</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Enjoy transparent and affordable pricing that ensures you get top value without hidden fees.
                        </p>
                    </div>

                    <!-- Local to Lawrenceville, GA -->
                    <div
                        class="flex flex-col items-center text-center space-y-4 bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                        <img src="../assets/images/store.png" alt="Local to Lawrenceville, GA" class="w-12 h-12">
                        <h3 class="text-2xl fira-sans-semibold">Local to Lawrenceville</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Based right here in Lawrenceville, we’re familiar with the local business landscape and
                            offer personalized, community-oriented service.
                        </p>
                    </div>

                    <!-- Tailored to Your Needs -->
                    <div
                        class="flex flex-col items-center text-center space-y-4 bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                        <img src="../assets/images/suit.png" alt="Tailored to Your Needs" class="w-12 h-12">
                        <h3 class="text-2xl fira-sans-semibold">Tailored to Your Needs</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Every website we design is crafted to perfectly reflect your brand and meet your unique
                            goals.
                        </p>
                    </div>

                    <!-- Ongoing Support & Maintenance -->
                    <div
                        class="flex flex-col items-center text-center space-y-4 bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                        <img src="../assets/images/technical-support.png" alt="Ongoing Support" class="w-12 h-12">
                        <h3 class="text-2xl fira-sans-semibold">Ongoing Support</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            We provide ongoing support and maintenance to keep your website running smoothly and
                            securely.
                        </p>
                    </div>

                    <!-- Fast Turnaround -->
                    <div
                        class="flex flex-col items-center text-center space-y-4 bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                        <img src="../assets/images/delivery.png" alt="Fast Turnaround" class="w-12 h-12">
                        <h3 class="text-2xl fira-sans-semibold">Fast Turnaround</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Our efficient design process ensures your website is ready quickly, so you can reach your
                            audience sooner.
                        </p>
                    </div>

                    <!-- Mobile-Friendly Designs -->
                    <div
                        class="flex flex-col items-center text-center space-y-4 bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
                        <img src="../assets/images/login.png" alt="Mobile-Friendly Designs" class="w-12 h-12">
                        <h3 class="text-2xl fira-sans-semibold">Mobile-Friendly Designs</h3>
                        <p class="text-gray-600 fira-sans-regular">
                            Every site we create is optimized for all devices, providing a seamless experience on
                            desktop, tablet, and mobile.
                        </p>
                    </div>
                </div>
            </div>
        </section>


        <!-- Portfolio Section -->
        <section id="portfolio" class="py-16 px-6 md:px-12 bg-gray-50">
            <div class="container mx-auto text-center">
                <h2 class="text-3xl md:text-4xl fira-sans-bold mb-8">Our Work</h2>
                <div class="grid gap-8 md:grid-cols-2">
                    <div class="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                        <img src="../assets/images/pfdaa-screenshot.png" alt="PFDAA Website"
                            class="rounded-lg mb-4 transition-transform transform hover:scale-110 duration-300 ease-in-out">
                        <h3 class="text-2xl fira-sans-semibold mb-2">Polish Folk Dance Association of America
                        </h3>
                        <p class="text-gray-600 mb-4 fira-sans-regular">
                            A website for a North American nonprofit, supporting folk dance groups in the US and Canada.
                        </p>
                        <a href="https://www.pfdaa.org" target="_blank" rel="noopener noreferrer"
                            class="text-blue-600 fira-sans-semibold hover:underline"> Visit Website
                        </a>
                    </div>

                    <div class="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105">
                        <img src="../assets/images/mazury-screenshot.png" alt="Mazury ATL Website"
                            class="rounded-lg mb-4 transition-transform transform hover:scale-110 duration-300 ease-in-out">
                        <h3 class="text-2xl fira-sans-semibold mb-2">Mazury Polish Folk Dance Ensemble</h3>
                        <p class="text-gray-600 mb-4 fira-sans-regular">
                            A showcase of Polish cultural events and folk dance performances.
                        </p>
                        <a href="https://www.mazuryatl.com" target="_blank" rel="noopener noreferrer"
                            class="text-blue-600 fira-sans-semibold hover:underline"> Visit Website
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- Order Process Section -->
        <section id="ordering-guide" class="py-16 px-6 md:px-12 bg-gray-50 flex items-center">
            <div class="container mx-auto flex flex-col md:flex-row items-center">
                <div class="md:w-1/2 md:pr-6">
                    <h2 class="text-3xl md:text-4xl fira-sans-bold mb-8">Get Started on Your Dream Website Today!</h2>
                    <p class="mb-4 fira-sans-regular">Follow these simple steps to bring your vision to life:</p>
                    <ol class="list-decimal mb-4 text-left">
                        <li class="mb-2 fira-sans-regular">Schedule a consultation with our team.</li>
                        <li class="mb-2 fira-sans-regular">Discuss your ideas and project requirements.</li>
                        <li class="mb-2 fira-sans-regular">Receive a personalized quote based on your needs.</li>
                        <li class="mb-2 fira-sans-regular">Review the proposal and make any adjustments.</li>
                        <li class="mb-2 fira-sans-regular">Once approved, we begin designing your website!</li>
                    </ol>
                    <p class="mb-4 fira-sans-regular">
                        Custom Features & Integrations can be tailored to your needs. Costs vary based on complexity and
                        requirements, ensuring you get the best value for your investment.
                    </p>
                    <p class="mb-4 fira-sans-regular">
                        Our Website Maintenance Plans are flexible and designed to fit your needs, starting at
                        competitive rates
                        for ongoing support and updates.
                    </p>
                </div>
                <div class="md:w-1/2">
                    <img src="../assets/images/contact.svg" alt="Ordering Guide" class="max-w-xs h-auto mx-auto">
                    <!-- Adjusted width to max-w-xs -->
                </div>
            </div>
        </section>


        <!-- Contact Section -->
        <section id="contact" class="py-16 px-6 md:px-12 bg-gray-50">
            <div class="container mx-auto text-center">
                <h2 class="text-3xl md:text-4xl fira-sans-bold mb-8">Contact Us</h2>
                <p class="mb-6 fira-sans-regular">Ready to start your project? Get in touch today!</p>
                <form @submit.prevent="submitForm" class="space-y-4 max-w-lg mx-auto">
                    <input type="text" name="name" v-model="name" placeholder="Your Name"
                        class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                        required />
                    <input type="email" name="email" v-model="email" placeholder="Your Email"
                        class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                        required />
                    <textarea name="message" v-model="message" rows="4" placeholder="Your Message"
                        class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                        required></textarea>
                    <label class="w-full flex items-center">
                        <span class="mr-4 text-left fira-sans-regular">Upload a File:</span>
                        <input type="file" name="upload"
                            class="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600" />
                    </label>
                    <button type="submit"
                        class="bg-blue-600 text-white px-6 py-3 rounded-lg text-lg fira-sans-semibold hover:bg-blue-500 transition duration-300 ease-in-out">Send
                        Message</button>
                </form>
            </div>
        </section>

        <!-- Footer Section -->
        <footer class="py-4 bg-gray-800 text-white text-center">
            <p class="mb-2 fira-sans-regular">&copy; 2024 PeachPixel Design. All Rights Reserved.</p>
            <p class="text-xs fira-sans-regular">
                <button @click="showAttributions"
                    class="text-blue-400 hover:underline focus:outline-none">Attributions</button>
            </p>
        </footer>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    name: 'HomePage',
    data() {
        return {
            name: '',
            email: '',
            message: ''
        }
    },
    methods: {
        async submitForm() {
            try {
                const formData = new FormData();
                formData.append('name', this.name);
                formData.append('email', this.email);
                formData.append('message', this.message);
                const fileInput = document.querySelector('input[type="file"]');
                if (fileInput.files.length > 0) {
                    formData.append('upload', fileInput.files[0]);
                }

                const response = await fetch('https://formspree.io/f/xrbggrgy', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json' // This helps with CORS issues.
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                // If successful
                Swal.fire({
                    title: 'Success!',
                    text: 'Your message has been sent successfully!',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });

                // Reset form fields
                this.name = '';
                this.email = '';
                this.message = '';
                fileInput.value = '';

            } catch (error) {
                // If there's an error
                Swal.fire({
                    title: 'Error!',
                    text: 'There was a problem sending your message. Please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        },
        showAttributions() {
            Swal.fire({
                title: 'Attributions',
                html: `
                <ul class="text-left text-sm fira-sans-regular">
                    <li><a href="https://www.flaticon.com/free-icons/tailor" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">Tailor icons created by ultimatearm - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/customer-service" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">Customer service icons created by Freepik - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/delivery" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">Delivery icons created by Kiranshastry - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/login" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">Login icons created by Freepik - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/pricing" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">Pricing icons created by monkik - Flaticon</a></li>
                    <li><a href="https://www.flaticon.com/free-icons/localization" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">Localization icons created by Vectors Tank - Flaticon</a></li>
                    <li>Assets used from <a href="https://iconscout.com/contributors/woobrodesign" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">WOOBRO LTD</a>. Licensed under <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer" class="text-blue-400 hover:underline">CC BY 4.0</a>.</li>
                </ul>
            `,
                icon: 'info',
                confirmButtonText: 'Close',
                width: '50rem'
            })
        },
    },
}
</script>

<style scoped>
/* Add custom styles if needed */
.parallax {
    background-attachment: fixed;
}
</style>
