// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';  // Import the router

// Import your global styles if necessary
import './assets/tailwind.css';

const app = createApp(App);

app.use(router);  // Tell your app to use the router
app.mount('#app');

