<template>
    <div>
      <h1>About Us</h1>
      <p>This is the About page for our web design business.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutPage'
  }
  </script>
  
  <style scoped>
  /* Optional: Add component-specific styles here */
  </style>
  